.helperText {
	color: var(--color-grey-700);
}

.wrapper {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: var(--spacing-xs) auto auto auto;
}

.linkWrapper { //To account for the right icon and make it appear centered.
	margin-left: 1em;
}

.quickLinksWrapper {
	background: var(--color-grey-050);
	overflow: hidden;
}
