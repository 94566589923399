@use 'global-styles/marble/base/base.scss';

.grid {
	display: grid;
	gap: var(--spacing-m);
	grid-template-columns: 1fr;
	margin-bottom: var(--spacing-m);

	@media screen and (min-width: base.$breakpoint-s) {
		grid-template-columns: repeat(2, 1fr);
	}
}
