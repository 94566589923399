.title {
	margin-bottom: var(--spacing-micro);
}

.list {
	display: flex;
	flex-wrap: wrap;
	gap: var(--spacing-micro);
}

.icon {
	flex-shrink: 0;
	height: 24px;
	margin: 0.2em 0;
	width: 24px;
}

.youtubeIcon {
	flex-shrink: 0;
	height: 24px;
	width: 108px;
}

.newsletter {
	font-size: var(--textsize-xs);
	margin: 0 0 var(--spacing-micro) 0;
}
