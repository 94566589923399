@use 'global-styles/marble/base/base.scss';

.filterSet {
	flex-basis: auto;
	flex-grow: 1;
	flex-shrink: 1;
	line-height: 0;
	margin: var(--spacing-micro) 0;
	overflow: visible;
	position: relative;
	white-space: nowrap;
}

.label {
	font-size: var(--textsize-s);
	font-weight: 600;
}

.wrap {
	flex-wrap: wrap;
}

.fieldset {
	display: inline-block;
}

.filtersWrapper {
	display: flex;
	gap: var(--spacing-micro);
}
