@use 'global-styles/marble/base/base.scss';

$bp-1440: 1440px;

.footer {
	--footer-border: var(--color-grey-200);
	background-color: var(--color-site-background);
	border-top: 1px solid var(--footer-border);
	color: var(--color-text);
	display: grid;
	font-family: var(--sans-serif);
	font-size: var(--textsize-xs);
	grid-template-columns: repeat(1, 1fr);
	margin: 0 auto;
	@media screen and (min-width: base.$breakpoint-m) {
		grid-template-columns: repeat(2, 1fr);
	}
	@media screen and (min-width: $bp-1440) {
		grid-template-columns: repeat(9, 1fr);
		grid-template-rows: 2fr auto;
	}

	:focus-visible {
		background-color: inherit;
		color: inherit;
	}
}

.link {
	display: inline-block;
	font-weight: 400;
	text-decoration: none;

	&:hover,
	&:focus {
		text-decoration: underline;
	}

}

.locations {
	border-bottom: 1px solid var(--footer-border);
	grid-column: span 1;
	padding: var(--spacing-xs) var(--spacing-component-margin);
	@media screen and (min-width: base.$breakpoint-m) {
		grid-row: span 2;
	}
	@media screen and (min-width: $bp-1440) {
		grid-column: span 3;
		padding: var(--spacing-m) var(--spacing-xs);
	}
}

.logoLink:focus-visible {
	background-color: var(--color-focus-state-background);
	line-height: 1;
	outline: 4px solid var(--color-focus-state-background);
}

.logoWrapper {
	height: 40px;
	margin-bottom: var(--spacing-m);
	vertical-align: top;
	width: 120px;
	@media screen and (min-width: $bp-1440) {
		display: inline-block;
		margin-right: var(--spacing-xxs);
	}
}

.museumAddressName {
	font-size: var(--textsize-s);
	margin-bottom: var(--spacing-micro);
}

.locationTitle {
	font-weight: 500;
}

.locationItems {
	display: flex;
	flex-direction: column;
	gap: var(--spacing-s);
	@media screen and (min-width: $bp-1440) {
		display: inline-flex;
	}
}

.navItems {
	display: flex;
	flex-direction: column;
	gap: var(--spacing-micro);
}

.navItem {
	font-weight: 400;
}

.newsletterLabel,
.title {
	color: var(--color-text-header);
	display: block;
	font-weight: 500;
	margin-bottom: var(--spacing-micro);
	padding-bottom: 0;
}

.linkContainer {
	display: grid;
	grid-column: span 1;
	grid-template-columns: repeat(1, 1fr);
	@media screen and (min-width: $bp-1440) {
		grid-column: span 6;
		grid-row: span 1;
		grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
	}
}

.navLink:hover {
	text-decoration: underline;
}

.links,
.newsletter {
	background-color: var(--color-component-background);
	padding: var(--spacing-xs) var(--spacing-component-margin);
	@media screen and (min-width: $bp-1440) {
		padding: var(--spacing-m) var(--spacing-xs);
	}
}

.links {
	border-bottom: 1px solid var(--footer-border);
	border-left: 1px solid var(--footer-border);
	flex-basis: 0;
	flex-grow: 1;
}

.bottomBar {
	flex-basis: 100%;
	flex-grow: 1;
}

.social {
	border-bottom: 1px solid var(--footer-border);
	@media screen and (min-width: base.$breakpoint-m) {
		grid-column-start: 2;
	}
	@media screen and (min-width: $bp-1440) {
		grid-column: span 2;
	}
}

.socialItem {
	display: inline-block;
	margin-right: var(--spacing-micro);
	width: auto;

	&:last-child {
		margin-right: 0;
	}
}

.socialLink {
	display: inline-block;
	line-height: 0;

	&:focus,
	&:hover {
		outline: 2px solid var(--color-text);
		outline-offset: 2px;
	}
}

.socialItemIcon {
	fill: currentColor;
	height: 16px;
	width: 16px;
	width: auto;
}

//Youtube lol
.iconYoutube {
	width: 72px;
}

.newsletter {
	border-left: 1px solid var(--footer-border);
	@media screen and (min-width: base.$breakpoint-m) {
		grid-column: span 4;
	}
}

.info {
	align-items: center;
	display: flex;
	flex-direction: column;
	grid-column: span 1;
	justify-content: space-between;
	padding: var(--spacing-xxs) var(--spacing-component-margin);
	@media screen and (min-width: base.$breakpoint-m) {
		grid-column: span 2;
	}
	@media screen and (min-width: $bp-1440) {
		flex-direction: row;
		grid-column: span 9;
		padding: var(--spacing-xxs) var(--spacing-xs);
	}
}

.infoItems {
	align-self: flex-start;
	display: inline-flex;
	flex-direction: column;
	gap: var(--spacing-micro);
	@media screen and (min-width: base.$breakpoint-m) {
		align-self: center;
		flex-direction: row;
	}
}

.infoLink {
	font-weight: 500;
}

.newsletterInputs {
	display: flex;
	flex-wrap: nowrap;
}

.newsletterInput {
	border-radius: base.$form-radius;
	height: auto;
	margin-bottom: 0;
	margin-left: 0;
	margin-right: var(--spacing-micro);
	overflow: hidden;
	width: 100%;

	&.is-invalid {
		border-color: var(--color-met-red-medium);
	}

	&:focus {
		outline-color: var(--color-grey-900);
	}
}

.formMessage {
	font-size: var(--textsize-xs);
	margin-top: var(--spacing-micro);

	&.error {
		color: var(--color-met-red-medium);
	}
}

