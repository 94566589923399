@forward 'typography';

@mixin line-clamp($lines) {
	-webkit-box-orient: vertical;
	/* stylelint-disable-next-line value-no-vendor-prefix */
	display: -webkit-box;
	-webkit-line-clamp: $lines;
	overflow: hidden;
	text-overflow: ellipsis;
}

//Normalize <button> elements
@mixin button-reset() {
	background: transparent;
	border: 0;
	color: currentcolor;
	font-family: var(--sans-serif);
	font-size: 1em;
}

@mixin productive-component {
	margin-left: auto;
	margin-right: auto;
	max-width: var(--productive-width-max);
}
