@use 'global-styles/marble/base/base.scss';
@use 'global-styles/marble/base/sizes.scss';

.artworkImageWrapper {
	align-items: center;
	background: var(--color-grey-100);
	display: flex;
	height: 350px;
	line-height: 0;
	margin-bottom: var(--spacing-micro);
	min-height: 350px;
	min-width: 250px;
	position: relative;
}

.artworkImage {
	height: auto;
	margin: auto;
	max-height: 100%;
	max-width: 85vw;
	width: auto;
}

.artworkImageButton {
	@include base.button-reset();
}

.artworkImageGrid {
	max-width: 100%;
}

.artworkTitle {
	font-size: var(--textsize-s);
	font-weight: 500;
	margin-bottom: 4px;

	a {
		text-decoration: none;

		/* stylelint-disable-next-line max-nesting-depth */
		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}
}

.artworkBody {
	font-size: var(--textsize-xs);
}

.artworkStudent {
	margin-bottom: 4px;
}

.artworkGrade {
	color: var(--color-grey-700);
}

.noImageSvg {
	color: var(--color-grey-400);
	display: block;
	height: 78px;
	margin: auto;
	width: 150px;
}
