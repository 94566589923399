@use 'global-styles/marble/base/base.scss';

.copyToClipboard {
	font-size: var(--textsize-xs);
}

.copyIcon {
	display: inline-block;
	fill: currentColor;
	margin-left: 4px;
}

.confirmation {
	color: var(--color-green-good-news-400);
}
