
@use 'global-styles/marble/base/base.scss';

.accordion {
	border-top: 1px solid var(--color-border);
}

.button {
	color: var(--color-link);
}

.details {
	border-bottom: 1px solid var(--color-border);
}

.summary {
	align-items: center;
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	margin: var(--title-padding) 0;

	&::after {
		content: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21 12.7875V11.2125H12.7875V3H11.2125V11.2125H3V12.7875H11.2125V21H12.7875V12.7875H21Z" fill="%23222222"/></svg>');
		height: 24px;
		width: 24px;
	}

	&::-webkit-details-marker {
		display: none;
	}

	[open] > &::after {
		content: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="3" y="11" width="18" height="1.5" fill="%23222222"/></svg>');
	}

	&:focus-visible {
		background-color: var(--color-focus-state-background);
		outline: 4px solid var(--color-focus-state-background);
	}
}

.heading {
	font-size: var(--textsize-m);
	font-weight: 600;
}

.body {
	padding-bottom: var(--spacing-xxs);
}
