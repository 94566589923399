@use 'sass:math';
// We can rename these with a find and replace, I just want to standardize all BPs.
$breakpoint-m: 960px; 
$breakpoint-s: 500px;

@function fontsize-calculator($mobile-size, $desktop-size) {
	$small-screen: 375; //iPhone width.
	$large-screen: 1440; //Macbook width.

	$base-size: math.div($mobile-size, 16);
	$calc: calc(#{$base-size}rem + (#{$desktop-size} - #{$mobile-size}) * ((100vw - #{$small-screen}px) / (#{$large-screen} - #{$small-screen})));
	@return $calc;
}

//Static Desktop fontsizes
$body-static-size: 16;
$h4-static-size: $body-static-size * 1.25;
$h3-static-size: $h4-static-size * 1.25;
$h2-static-size: $h3-static-size * 1.25;
$h1-static-size: $h2-static-size * 1.25;

// Fluid Typography Sizes.
$textsize-xxs: fontsize-calculator(10, 12);
$textsize-xs: fontsize-calculator(12, 14);
$textsize-s: fontsize-calculator(14, $body-static-size); //Body Text
$textsize-m: fontsize-calculator($body-static-size, $h4-static-size); //H4
$textsize-l: fontsize-calculator($h4-static-size, $h3-static-size); //H3
$textsize-xl: fontsize-calculator($h3-static-size, $h2-static-size); //H2
$textsize-xxl: fontsize-calculator($h2-static-size, $h1-static-size); //H1
$textsize-xxxl: fontsize-calculator(60, 68); //Special H1 for the homepage

//More Typography sizes;
$line-length-max: 50em;

//Corner radii
$radius-sharp: 4px;
$radius-blunt: 8px;
$radius-soft: 16px;
$form-radius: $radius-sharp; // TODO: deprecate
$soft-radius: $radius-soft; // TODO: deprecate
