@use 'global-styles/marble/base/sizes.scss';

$playlist-slide-width: 350px;

.searchSection {
	margin-top: var(--spacing-micro);

	@media only screen and (min-width: sizes.$breakpoint-m) {
		margin-bottom: var(--spacing-micro);
	}

	.inputWrapper {
		margin: 0 auto;
	}
}

.playlistShelf {
	margin-bottom: var(--spacing-m);
}

.playlistShelfTitle {
	margin-bottom: var(--spacing-xxs);
}

.description {
	margin: 0 auto;
}

.inputWrapper {
	display: block;
	margin: 0;
	position: relative;
}

.credits {
	margin: var(--spacing-m) var(--spacing-component-margin);
	text-align: center;
}

.sponsorLogo {
	font-size: var(--textsize-xs);
	font-weight: 500;
	margin: 0 auto;
	max-width: 150px;
	text-align: left;
	width: 100%;

	svg {
		height: 100%;
		width: 100%;
	}
}

.mainWrapper {
	column-gap: var(--spacing-m);
	display: flex;
	flex-direction: column;
	row-gap: var(--spacing-s);

	@media (orientation: landscape) {
		align-items: flex-start;
		flex-direction: row-reverse;
	}
}

.artwork,
.playerWrapper {
	flex-basis: auto;
	flex-grow: 0;
	flex-shrink: 1;

	@media (orientation: landscape) {
		flex-basis: 50%;
	}
}

.artwork {
	align-items: center;
	align-self: flex-start;
	display: flex;
	flex-direction: column;
	gap: var(--spacing-xxs);
	justify-content: center;
	min-height: 30vh;
	position: relative;
	width: 100%;

	@media (orientation: landscape) {
		min-height: 50vh;
	}
}

.crdImageWrapper {
	align-items: center;
	background-color: var(--color-grey-100);
	display: flex;
	justify-content: center;
	width: 100%;
}

.artworkImage {
	max-height: 50vh;
	max-width: 100%;
	width: auto;
}

.artworkThumbnail {
	.artworkImage {
		height: auto;
		left: auto;
		position: relative;
		top: auto;
		width: auto;
	}
}

.noImageSvg {
	height: var(--textsize-xxl);
	width: var(--textsize-xxl);
}


.descriptionWrapper {
	font-size: var(--textsize-xs);
	margin-bottom: var(--spacing-xxs);
}

.descriptionTitle {
	font-size: var(--textsize-xs);
	font-weight: 600;
}

.noTranscript {
	background-color: var(--color-grey-100);
	font-size: var(--textsize-xs);
	font-weight: 500;
	margin-top: var(--spacing-s);
	padding: var(--spacing-xxs);

	.has-transcript & {
		display: none;
	}
}

.searchIcon {
	svg {
		height: 14px;
		width: 14px;
	}
}

.playlistSlideDescription {
	font-size: var(--textsize-xs);
	max-width: $playlist-slide-width;
}

.playlistSlideTranslations {
	color: var(--color-blue-bright);
	font-size: var(--textsize-xs);
	font-weight: 600;
}

.plalistSlideTranslationsDarkMode {
	@media (prefers-color-scheme: dark) {
		color: var(--color-blue-900);
	}
}

.playlistExhibitionSlideSubext {
	color: var(--color-text);
	font-size: var(--textsize-xs);
	font-weight: 500;
}

.errorWrapper {
	left: 0;
	position: absolute;
	right: 0;
	top: calc(100% + 4px);
}
