@use 'sass:math';

@mixin typography-h1 {
	font-size: var(--textsize-xxl);
	font-weight: 700;
	letter-spacing: -0.01em;
	line-height: 1.2;
}

@mixin typography-h2 {
	font-size: var(--textsize-xl);
	font-weight: 600;
	letter-spacing: -0.01em;
	line-height: 1.3;
}

@mixin typography-h3 {
	font-size: var(--textsize-l);
	font-weight: 600;
	letter-spacing: -0.01em;
	line-height: 1.3;
}

@mixin typography-h4 {
	font-size: var(--textsize-m);
	font-weight: 700;
	line-height: 1.3;
}

@mixin typography-h5 {
	font-size: var(--textsize-s);
	font-weight: 700;
}

@mixin typography-tag {
	font-family: var(--sans-serif);
	font-size: var(--textsize-xxs);
	font-weight: 700;
	letter-spacing: 0.05em;
	line-height: 0.7;
	text-transform: uppercase;
}
