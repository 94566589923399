@use 'global-styles/marble/base/base.scss';

.filter {
	display: inline-block;
	position: relative;
}

.filterControl,
.filterInput {
	appearance: none;
	border: 0;
	border-radius: 0;
	bottom: 0;
	left: 0;
	margin: 0;
	max-height: 100%;
	position: absolute;
	width: 100%;
	z-index: base.z('positive-default');

	&:focus-visible {
		background-color: transparent;
		color: currentColor;
		outline: 0;
	}
}

.filterLabel {
	align-items: center;
	background: var(--color-white);
	border: 2px solid var(--color-grey-200);
	border-radius: 32px;
	box-sizing: border-box;
	color: var(--color-grey-900);
	display: flex;
	font-family: var(--sans-serif);
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	height: 32px;
	padding: 0 15px;
	transition: all 0.2s;
	user-select: none; // https://www.a11ymatters.com/pattern/checkbox/

	&:focus,
	&:hover {
		background-color: var(--color-grey-200);
		cursor: pointer;
	}

	:focus-visible + & {
		outline: 2px solid currentColor;
		outline-offset: 2px;
	}

	:focus-visible + &::after {
		border-radius: 100px;
	}

	:active + & {
		filter: brightness(0.7);
	}

	:checked + & {
		background: var(--color-grey-900);
		border: 2px solid var(--color-grey-900);
		color: var(--color-white);
	}
}

.filterControlIcon {
	margin-left: var(--spacing-micro);
}
