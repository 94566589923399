.container {
	position: relative;
}

.button {
	display: flex;
	gap: var(--spacing-micro);
	width: 100%;
}

.links {
	background-color: var(--color-site-background);
	border: 1px solid var(--color-grey-200);
	border-radius: 4px;
	box-shadow: 1px 1px 1px var(--color-active-shadow);
	left: 0;
	position: absolute;
	right: 0;
	top: calc(100% + 4px);
}

.link {
	border: 0;
	display: block;
	font-weight: normal;
	padding: var(--spacing-micro) var(--spacing-xxs);
	text-decoration: none;
	white-space: nowrap;

	&:hover,
	&:focus {
		background-color: var(--color-grey-200);
	}
}

.up {
	transform: rotate(180deg);
}
