@use 'global-styles/marble/base/base.scss';
$breakpoint: base.$breakpoint-m;

.alert {
	color: var(--color-white-opaque);
	display: grid;
	grid-auto-columns: min-content 1fr max-content;
	margin: 0 auto;
	padding: var(--spacing-micro) 0;
	row-gap: var(--spacing-micro);
	width: 85vw;
}

.alertContainer {
	background-color: var(--color-grey-900);
}

.alertIcon {
	align-items: center;
	display: flex;
	grid-column: 1;
	grid-row: 1 / span 2;
	height: 24px;
	justify-content: center;
	justify-self: start;
	margin-right: var(--spacing-micro);
	width: 24px;

	@media screen and (min-width: $breakpoint) {
		flex-direction: row;
		grid-row: 1 / span 1;
	}

	svg {
		height: 18px;
		height: 18px;
	}
}

.alertText {
	font-size: var(--textsize-xs);
	grid-column: 2;
	grid-row: 1;

	@media screen and (min-width: $breakpoint) {
		align-items: center;
		display: flex;
		margin-right: var(--spacing-s);
	}

	p {
		max-width: none;
	}
}

.alertCta {
	font-size: var(--textsize-xs);
	grid-column: 2;
	grid-row: 2;

	@media screen and (min-width: $breakpoint) {
		align-items: center;
		display: flex;
		grid-column: 3;
		grid-row: 1;
		justify-self: end;
	}
}
