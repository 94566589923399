@use 'global-styles/marble/base/base.scss';

.arrow {
	--button-size: 72px;
	background-color: var(--color-white);
	border-radius: 50%;
	color: var(--color-grey-900);
	font-size: 30px;
	height: var(--button-size);
	padding: var(--spacing-micro);
	position: fixed;
	top: calc(50% - var(--button-size));
	width: var(--button-size);
	z-index: base.z('positive-default');

	&:disabled {
		opacity: 0.5;
	}

	&:hover {
		opacity: 0.8;
	}

	svg {
		height: 80%;
		width: auto;
	}
}

.forward {
	right: var(--spacing-xxs);
	transform: rotate(180deg);
}

.back {
	left: var(--spacing-xxs);
}
