@use 'global-styles/marble/base/base.scss';

.grid {
	--grid-gap: var(--spacing-micro);
	display: grid;
	gap: var(--grid-gap);
	
}

.gridByColumnCount {
	grid-template-columns: 1fr;
	@media screen and (min-width: base.$breakpoint-m) {
		grid-template-columns: repeat(var(--grid-columns), 1fr); 
	}
}

.gridByColumnWidth {
	grid-template-columns: repeat(auto-fit, minmax(var(--column-width), 1fr));
}
