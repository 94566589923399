@use 'global-styles/marble/base/base.scss';

.featuredContent {
	display: grid;
	gap: var(--spacing-xs);
	grid-template-columns: repeat(6, 1fr);
	@media screen and (min-width: base.$breakpoint-m) {
		gap: var(--spacing-xxs);
	}
}
	
.featuredContent > * {
	grid-column: span 3;
	@media screen and (max-width: base.$breakpoint-m) {
		grid-column: span 6;
	}
}

.featuredContent > *:nth-last-child(3n+3):not(:nth-child(2)),
.featuredContent > *:nth-last-child(3n+3):not(:nth-child(2))~ * {
	grid-column: span 2;
	@media screen and (max-width: base.$breakpoint-m) {
		grid-column: span 6;
	}
}

.clampedWidth {
	margin: 0 var(--spacing-component-margin);
}
