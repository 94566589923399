@use 'global-styles/marble/base/base';

.entriesByLetter {
	display: flex;
	margin-top: var(--spacing-xs);

	@media (orientation: portrait) {
		flex-direction: column;
		margin-bottom: var(--spacing-s);
		padding-bottom: var(--spacing-xs);
	}
}

.entry {
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	gap: var(--spacing-micro);
	line-height: 1;
	margin-bottom: var(--spacing-xs);
}


.letter {
	padding-right: var(--spacing-m);

	@media (orientation: portrait) {
		margin-bottom: var(--spacing-s);
	}
}


.lifetime {
	color: var(--color-grey-700);
}
