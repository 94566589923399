@use 'global-styles/marble/base/base.scss';

.article {
	--max-width-article: var(--line-length-comfortable);
	margin: 0 auto;
	width: min(var(--max-width-article), var(--productive-width-max)); //TODO should we remove this bit and just put this component in PageSections?

	:global(.extraLarge) {
		transition: all 0.2s;
	
		@media (min-width: base.$breakpoint-m) {
			margin: var(--spacing-s) calc(-1* var(--spacing-xl));
			max-width: calc(100% + (var(--spacing-xl)*2));
		}
	}

	blockquote {
		font-size: var(--textsize-l);
		margin: var(--spacing-xs) 0;
	
		@media (min-width: base.$breakpoint-m) {
			margin: var(--spacing-s) calc(-1* var(--spacing-xl));
		}
	}

	h3:not(:first-child) {
		margin-top: var(--spacing-s);
	}
}

.divider {
	border: 0;
	border-top: 1px solid var(--color-divider);
	display: block;
	height: 1px;
	margin-top: var(--spacing-m);
	max-width: var(--line-length-comfortable);
	padding: 0;
}

