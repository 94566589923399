@use 'global-styles/marble/base/base.scss';

.homepage-email {
	background-color: var(--color-met-red);
	color: var(--color-white);
	width: 100%;

	*:focus {
		outline-color: var(--color-white);
	}
}

.wrapper {
	align-items: center;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin: 0 auto;
	max-width: var(--productive-width-max);
	padding-top: 40px;
}

.label {
	color: var(--color-white);
	display: flex;
	width: 100%;
	@media screen and (min-width: base.$breakpoint-m) {
		width: auto;
	}
}

.title {
	@include base.typography-h3;
	background-color: var(--color-met-red);
	color: var(--color-white);
	flex-basis: 100%;
	margin-bottom: 10px;
	text-align: center;
	@media screen and (min-width: base.$breakpoint-m) {
		flex-basis: auto;
		font-size: 30px;
		font-weight: 500;
		margin-bottom: 26px;
		text-align: left;
	}
}

.form-fields {
	align-self: auto;
	color: var(--color-text);
	flex-grow: 1;
	margin: 0 auto 40px;
	min-height: 65px;
	@media screen and (min-width: base.$breakpoint-s) {
		align-self: center;
		max-width: 550px;
	}
	@media screen and (min-width: base.$breakpoint-m) {
		align-self: auto;
		flex-grow: 1;
		margin: 0 10px;
		min-height: none;
		padding-left: 40px;
	}
}

.input-wrapper {
	margin-bottom: 10px;
	position: relative;

	input {
		font-size: 16px;
		padding-right: 40px;
		text-overflow: ellipsis;
		width: 100%;
	}
}


.submit {
	background-color: transparent;
	border: 0;
	cursor: pointer;
	height: 38px;
	outline: none;
	position: absolute;
	right: 1px;
	top: 1px;
	transition: all 0.3s;
	width: 38px;

	&:hover,
	&:focus {
		/* stylelint-disable-next-line max-nesting-depth */
		.button-icon {
			color: var(--color-met-red);
		}
	}

	&:active {
		background-color: var(--color-met-red-medium);
	}
}

.button-icon {
	height: 20px;
	width: 20px;
}

.validation-error {
	color: var(--color-white);
	font-size: 14px;
	font-weight: 400;
}

.confirm {
	background-color: var(--color-white);
	color: var(--color-grey-900);
	padding: 10px;
}
