@use 'global-styles/marble/base/base.scss';

.wrapper {
	display: flex;
	justify-content: space-between;
	margin-left: auto;
	margin-right: auto;
}

.button {
	align-items: center;
	display: flex;
	gap: 4px;
	justify-self: flex-start;
	line-height: normal;

	&:hover {
		text-decoration: underline;
	}

	&[disabled] {
		background-color: transparent;
		color: var(--color-grey-400);
	}

	&[disabled]:hover {
		background-color: transparent;
		color: var(--color-grey-400);
		text-decoration: none;
	}
}

.forwardButton {
	justify-self: flex-end;
	margin-left: auto;
}
