@use 'global-styles/marble/base/base.scss';

.select {
	font-size: var(--textsize-xxs);
	z-index: base.z('select-component');
}

.label {
	font-weight: 600;
}

.option {
	background-color: transparent;
	cursor: default;
	padding: 6px var(--spacing-micro);

	&:active,
	&:hover,
	&:focus {
		background-color: var(--color-focus-state-background);
	}
}

.count {
	color: var(--color-text);
}
