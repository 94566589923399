@use 'global-styles/marble/base/base.scss';

.map {
	align-items: center;
	border: 1px solid var(--color-grey-900);
	border-radius: 4px;
	display: flex;
	height: 50vh;
	justify-content: center;
	max-width: 100%;
	overflow: hidden;

	:global(.case) {
		cursor: pointer;
		transition: filter 0.2s linear;

		/* stylelint-disable-next-line max-nesting-depth */
		&:hover {
			filter: brightness(0.9);
		}
	}
}

.svgWrapper svg {
	background-color: var(--color-site-background);
	height: auto;
	max-height: calc(50vh);
	max-width: 100%;
	padding: var(--spacing-micro);
	width: auto;
}

.loadingScrim {
	align-items: center;
	background-color: var(--color-site-background);
	bottom: 0;
	display: flex;
	font-size: var(--textsize-xxl);
	justify-content: center;
	left: 0;
	opacity: 0.8;
	position: fixed;
	right: 0;
	top: 0;
	transition: opacity 0.4s;
	z-index: base.z('modal-scrim');
}
