.wrapper {
	margin: var(--spacing-s) auto;
	max-width: var(--productive-width-max);
	width: min(var(--max-width-article), var(--productive-width-max));
	--max-width-article: var(--line-length-comfortable); 
}

.divider {
	border: 0;
	border-top: 1px solid var(--color-divider);
	display: block;
	height: 1px;
	margin: var(--spacing-s) auto;
	padding: 0;
}

.title {
	margin-bottom: var(--spacing-micro);
}
