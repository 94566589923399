@use 'global-styles/marble/base/base.scss';

.bar {
	align-items: center;
	background-color: var(--color-component-background);
	bottom: 0;
	display: flex;
	gap: var(--spacing-xs);
	justify-content: space-between;
	left: 0;
	padding: var(--spacing-micro) var(--spacing-component-margin);
	position: fixed;
	right: 0;
	z-index: base.z('kiosk-bar');
}
