@use 'global-styles/marble/base/base.scss';

.background {
	backdrop-filter: brightness(1);
	bottom: 0;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	transition: all 0.4s;
	z-index: base.z('nav-scrim');
}

.visible {
	backdrop-filter: brightness(0.7);
}
