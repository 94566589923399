.wrapper {
	margin: var(--spacing-s) 0;
}

.clampedWidthWrapper {
	margin: var(--spacing-s) auto;
	max-width: var(--productive-width-max);
	width: 100%;
}

.titleClamped {
	margin: 0 var(--spacing-component-margin) var(--spacing-micro);
}

.title {
	margin: 0 0 var(--spacing-micro);
}
