/* stylelint-disable value-no-vendor-prefix */
@use 'global-styles/marble/base/base.scss';

.wrapper {
	--max-wrapper-height: 100%;
	max-height: var(--max-wrapper-height);
	overflow: hidden;
	position: relative;
}

.hasTransition {
	transition: max-height 0.2s;
}

.button {
	@include base.button-reset;
	align-items: center;
	color: var(--color-link);
	display: flex;
	font-size: var(--textsize-xs);
	font-weight: 600;
	gap: 4px;
	
	&:hover {
		text-decoration: underline;
	}

	&:focus-visible {
		background-color: var(--color-focus-state-background);
		outline: 0;
		text-decoration: underline;
	}
}

.hasOverFlow {
	&::after {
		background: linear-gradient(to top,
		var(--color-site-background) 0%,
		transparent 100%);
		bottom: 0;
		content: '';
		height: 1.5em;
		left: 0;
		position: absolute;
		right: 0;
		width: 100%;
	}
}

.icon {
	transition: all 0.2s linear;
}

.up {
	transform: rotate(180deg);
}
