@use 'global-styles/marble/base/base.scss';

.wrapper {
	--color-divider: var(--color-border);
	--input-height: 48px;
	background-color: var(--color-white);
	border: 1.5px solid var(--color-divider);
	border-radius: base.$radius-sharp;
	display: flex;
	height: var(--input-height);
	width: 100%;

	&:hover {
		--color-divider: var(--color-border-active);
	}

	&:focus-within {
		--color-divider: var(--color-border-active);
	}
}

.input {
	background-color: var(--color-white);
	box-sizing: border-box;
	color: var(--color-grey-900);
	flex-basis: 100%;
	font-size: max(16px, var(--textsize-s));
	height: 100%;
	padding: var(--spacing-micro);
	text-overflow: ellipsis;
	width: 100%;

	&:focus-visible {
		background: var(--color-white);
		outline-width: 0;
	}

	&::-webkit-search-cancel-button {
		appearance: none;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.561' height='15.561' viewBox='0 0 15.561 15.561'%3E%3Cpath fill='currentColor' d='M1007.78,1008.36l-1.42,1.42-6.36-6.37-6.364,6.37-1.414-1.42,6.364-6.36-6.364-6.364,1.414-1.414,6.364,6.368,6.36-6.368,1.42,1.414-6.37,6.364Z' transform='translate(-992.219 -994.219)'%3E%3C/path%3E%3C/svg%3E");
		background-size: 0.75em 0.75em;
		cursor: pointer;
		height: 0.75em;
		width: 0.75em;
	}
}

.submitButton {
	border-left: 1.5px solid var(--color-divider);
	border-radius: 0;
	color: currentColor;
	height: 100%;
	line-height: 0;
	width: calc(var(--input-height) * 1.5);

	.searching &,
	.wrapper:focus-within &,
	&:hover {
		background-color: var(--color-link);
		color: var(--color-white);
	}

	&:focus-visible {
		background-color: var(--color-met-red-medium);
		outline-width: 0;	
	}

	svg {
		height: 0.75em;
		width: 0.75em;
	}
}

.iconContainer {
	align-items: center;
	display: flex;
	justify-content: center;
	margin: auto 0 auto var(--spacing-micro);

	svg {
		display: block;
	}
}
