@use 'global-styles/marble/base/base';

.clearableFilter {
	// from tabler icons (name: "x")
	--black-x: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none' /%3E%3Cpath d='M18 6l-12 12' /%3E%3Cpath d='M6 6l12 12' /%3E%3C/svg%3E");
	--white-x: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none' /%3E%3Cpath d='M18 6l-12 12' /%3E%3Cpath d='M6 6l12 12' /%3E%3C/svg%3E");
}

.activeFilter {
	@include base.button-reset;
	align-items: center;
	background-color: var(--color-grey-900);

	border-radius: var(--textsize-xxs);
	color: var(--color-white);
	display: flex;
	flex-wrap: nowrap;
	font-size: var(--textsize-xxs);
	line-height: 1;
	padding: var(--spacing-micro);

	&::after {
		content: var(--white-x);
		height: var(--textsize-xxs);
		margin-left: var(--spacing-micro);
		width: var(--textsize-xxs);
	}

	&:focus,
	&:hover {
		background-color: var(--color-purple);
		outline: none;
	}
}

.inverted {
	background-color: var(--color-site-background);
	color: var(--color-link);

	&::after {
		content: var(--black-x);
		height: var(--textsize-xxs);
		margin-left: var(--spacing-micro);
		width: var(--textsize-xxs);
	}
}

.inverted:hover,
.inverted:focus {
	background-color: var(--color-purple);
	color: var(--color-white);
	outline: none;
	
	&::after {
		content: var(--white-x);
	}
}
