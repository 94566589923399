@use 'global-styles/marble/base/base';

.quickLinks {
	column-gap: var(--spacing-xxs);
	display: grid;
	grid-template-columns: minmax(0, 1fr);
	row-gap: var(--spacing-micro);
	@media screen and (min-width: base.$breakpoint-m) {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
}

.link {
	--link-background-color: var(--color-white); //TODO theme when branch lands.
	--link-color: var(--color-grey-900); //TODO theme when branch lands.
	--link-color-accent: var(--color-grey-700);
	align-items: center;
	background-color: var(--link-background-color);
	border: 1px solid currentColor;
	border-radius: base.$radius-blunt;
	color: var(--link-color);
	display: flex;
	gap: var(--spacing-xxs);
	height: 100%;
	justify-content: space-between;
	padding: 16px; //Not MVP code -Julie demands a hardcoded value here! (JK but really)
	text-decoration: none;

	&:hover,
	&:focus {
		--link-background-color: var(--color-grey-900);
		--link-color: var(--color-white);
		--link-color-accent: var(--color-white);
		color: var(--link-color);
	}
}

.svgWrapper {
	align-items: center;
	background-color: currentColor;
	border-radius: 50%;
	display: flex;
	flex-shrink: 0;
	height: 1.5em;
	justify-content: center;
	width: 1.5em;

	svg {
		color: var(--link-background-color);
		height: 50%;
		width: 50%;
	}
}

.textWrapper {
	overflow: hidden;;
}

.title {
	font-weight: 600;
}

.description {
	color: var(--link-color-accent);
	font-size: var(--textsize-xs);
	font-weight: 400;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	* { //Annoying that we need to do this, but <p>s act weird with ellipsis without this.
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}
