@use 'global-styles/marble/base/base.scss';
$cq-publication-card: base.$breakpoint-s;

.cardWrapper {
	container-name: publicationCard;
	container-type: inline-size;
	list-style: none;
}

.card {
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	gap: var(--spacing-micro);

	@container publicationCard (width > #{$cq-publication-card}) {
		flex-direction: row;
	}
}

.imageWrapper {
	align-items: center;
	align-self: center;
	background-color: var(--color-grey-050);
	display: flex;
	flex-basis: 200px;
	flex-grow: 0;
	flex-shrink: 0;
	justify-content: center;
	line-height: 0;
	padding: var(--spacing-xxs);
	position: relative;

	@container publicationCard (width > #{$cq-publication-card}) {
		width: 200px;
	}
}

.image {
	box-shadow: 8px 8px 0 var(--color-grey-400);
	max-height: 100%;
	max-width: 100%;
	transition: all 0.2s;

	.imageWrapper:hover & {
		box-shadow: -8px -8px 0 var(--color-grey-400);
	}
}

.authors,
.date {
	color: var(--color-grey-700);
	font-size: var(--textsize-xxs);
}

.body {
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	gap: var(--spacing-xxs);

	@container publicationCard (width > #{$cq-publication-card}) {
		padding: var(--spacing-xs);
	}
}

.mainText {
	display: flex;
	flex-direction: column;
	gap: var(--spacing-micro);
}

.description {
	@include base.line-clamp(5);
	@container publicationCard (width > #{$cq-publication-card}) {
		@include base.line-clamp(3);
	}
}

.heading {
	font-weight: 500;
}

.badges {
	display: flex;
	flex-direction: column;
	gap: var(--spacing-micro);
}

.badgeWrapper {
	align-self: flex-start;
}
