
.filter {
	align-items: center;
	cursor: pointer;
	display: flex;
	gap: 8px;
	line-height: 1;
}

.input {
	flex-shrink: 0;
	height: 1.4em;
	padding: 4px;
	width: 1.4em;
}

.label {
	color: var(--color-link);
}

.disabled {
	color: var(--color-grey-700);
}
