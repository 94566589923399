.card {
	color: var(--color-white);
	flex-shrink: 0;
	height: 340px;
	margin: var(--spacing-micro) 0;
	position: relative;
	width: 280px;

	:focus-visible {
		color: var(--color-grey-900);
	}
}

.figure {
	max-height: 100%;
	max-width: 100%;
	overflow: hidden;
	transition: all 0.2s;

	.card:hover &,
	.card:focus-within & {
		transform: scale(1.06);
	}
}

.title {
	bottom: 0;
	color: var(--color-white);
	display: inline-block;
	padding: 36px;
	position: absolute;

	a:focus-visible & {
		background-color: var(--color-focus-state-background);
	}
}
