@use 'global-styles/marble/base/base.scss';

.default {
	height: auto;
	max-width: 100%; 
}

.fill {
	object-fit: cover;
}

.img[data-loading] {
	--img-bg-color: var(--color-grey-800);
	--img-color: var(--color-white);
	background-color: var(--img-bg-color);
	display: inline-block;
	position: relative;

	&::after {
		align-items: center;
		background-color: var(--img-bg-color);
		bottom: 0;
		color: var(--img-color);
		content: attr(alt);
		display: flex;
		font-size: var(--textsize-m);
		font-weight: 500;
		justify-content: center;
		left: 0;
		line-height: 1.1;
		padding: 10%;
		position: absolute;
		right: 0;
		top: 0;
	}
}
