@use 'global-styles/marble/base/base';
$micro-player-height: 80px;

.mediaSection {
	.micro-player & {
		flex-direction: row;
		padding: 0;
	}
}

.body {
	.micro-player & {
		justify-content: center;
		min-height: $micro-player-height;
		padding: var(--spacing-micro);
	}
}

.title,
.subtitle {
	.micro-player & {
		font-size: var(--textsize-xs);
		margin-bottom: 0;
	}
}

.imageSection {
	.micro-player & {
		flex-basis: $micro-player-height;
		flex-grow: 0;
		flex-shrink: 0;
		margin: 0;
	}
}

.imageWrapper {
	.micro-player & {
		display: none;
	}
}

.transcriptToggle {
	.micro-player & {
		padding: var(--spacing-micro);
	}
}

.seekBack,
.seekForward {
	.micro-player & {
		display: none;
	}
}

.playWrapper {
	.micro-player & {
		align-items: center;
		display: flex;
		font-size: var(--textsize-xl);
		height: $micro-player-height;
		justify-content: center;
		left: 0;
		position: absolute;
		text-align: center;
		top: 0;
		width: $micro-player-height;
	}
}

.timeControls {
	.micro-player & {
		display: none;
		position: relative;
		width: 100%;
	}

	.isPlaying.micro-player & {
		display: block;
	}
}

.controlsWrapper {
	.micro-player & {
		margin: 0;
	}
}

// This is a little sketchy, but without changing the HTML this is the clearest way I could see to get the elapsed/remaining
// time on the same line as the scrubber. I want to avoid having specific HTML for any given mode so we can easily transform
// the full or mini player into the micro (like if we want to pin it to the top of the page or something)

.scrubberWrapper {
	.micro-player & {
		left: var(--spacing-m);
		position: absolute;
		right: var(--spacing-m);
	}
}
