@use 'global-styles/marble/base/base.scss';
$menu-size: 300px;

.languageFilterContainer {
	display: inline-block;
	text-align: left;
	width: 100%;

	@media only screen and (min-width: base.$breakpoint-m) {
		min-width: $menu-size;
		width: fit-content;
	}
}

.languageFilterControl {
	height: 45px;
	
	&:focus-within {
		background-color: #c8d8eb;
	}

	@media only screen and (min-width: base.$breakpoint-m) {
		margin-left: auto;
		max-width: fit-content;
	}
}

.languageFilterValueContainer {
	@media only screen and (min-width: base.$breakpoint-m) {
		padding-right: 0 !important;
	}
}

.languageFilterValueContainerContents {
	align-items: center;
	cursor: pointer;
	display: flex;
}

.languageFilterControlText {
	font-size: var(--textsize-s);
	font-weight: 500;
	line-height: 29px;
}

.languageFilterIcon {
	line-height: 29px;
	margin-right: 7px;

	svg {
		display: block;
	}
}

.languageFilterIndicatorSeparator {
	display: none;
}

.chevron {
	margin-right: 7px;

	svg {
		height: 12px;
		width: 12px;
	}
}

.chevronDown {
	transform: rotate(180deg);
}

.languageFilterMenuList {
	padding: 0 !important;
}

.languageFilterMenuItem {
	align-items: center;
	border-bottom: var(--color-divider) solid 1px;
	cursor: pointer;
	display: flex;
	flex-wrap: nowrap;
	padding: 7px;
}

.languageFilterMenuCheckbox {
	margin: 0 7px;
	width: 16px;

	.svg {
		fill: #333;
		vertical-align: sub;
	}
}

.languageFilterLanguage {
	font-size: var(--textsize-s);
	font-weight: 400;
}

.languageFilterDescription {
	color: var(--color-grey-700);
	flex: 1;
	font-size: var(--textsize-xs);
	overflow: hidden;
	padding-left: var(--spacing-xxs);
	padding-right: 7px;
	text-align: right;
	text-overflow: ellipsis;
	white-space: nowrap;
}
