@use 'global-styles/marble/base/sizes';

.breadcrumbs {
	font-size: var(--textsize-xs);
	margin: var(--spacing-xxs) var(--spacing-component-margin);

	>:nth-last-child(2)::after {
		content: none;
		@media screen and (min-width: sizes.$breakpoint-s) {
			content: '/';
		}
	}

	>:nth-last-child(2)::before {
		aspect-ratio: 1;
		content: url('/icons/public-chevron.svg');
		height: 0.8em;
		margin-right: 4px;
		@media screen and (min-width: sizes.$breakpoint-s) {
			content: none;
		}
	}

	>:not(:nth-last-child(2)) {
		display: none;
		@media screen and (min-width: sizes.$breakpoint-s) {
			display: inline-flex;
		}
	}
}

.linkWrapper {
	color: var(--color-link);
	display: inline-flex;
	overflow: hidden;

	&::after {
		content: '/';
		padding: 0 8px;
	}
}

.lastItem {
	color: var(--color-link);
	font-weight: 600;
}

.truncatedSegment {
	display: inline-block;
	max-width: 40vw;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.link {
	font-weight: 400;
	min-width: 16px;
	text-decoration: none;

	&:hover,
	&:focus {
		text-decoration: underline;
	}
}

.iconWrapper {
	padding: 0.1em;
}

.svg {
	font-size: 0.8em;

	.link:hover &,
	.link:focus & {
		fill: currentColor;
	}
}
