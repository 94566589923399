.checkboxGroupHeading {
	align-items: center;
	display: flex;
	gap: 8px;
	height: 1.4em;
}

.checkboxGroups {
	display: flex;
	flex-direction: column;
	gap: var(--spacing-xxs);
	padding-bottom: var(--spacing-xs);
}

// "narrow your search" section counts of selected filters
.filterCount {
	align-items: center;
	background-color: var(--color-text-header);
	border-radius: 50px;
	color: var(--color-site-background);
	display: inline-flex;
	font-size: var(--textsize-xs);
	height: 1.6em;
	justify-content: center;
	width: 1.6em;
}
