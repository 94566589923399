@use 'global-styles/marble/base/base';

.container {
	border-bottom: 1px solid var(--color-divider);
	border-top: 1px solid var(--color-divider);
	margin-bottom: var(--spacing-m);
	margin-left: auto;
	margin-right: auto;
	max-width: var(--productive-width-max);
	padding: var(--spacing-s) 0;
}

.heading {
	color: var(--color-met-red-medium);
	font-weight: 500;
	margin-bottom: var(--spacing-micro);
}
