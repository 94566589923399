@use 'global-styles/marble/base/base.scss';

.redundantlink {
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: base.z('redundant-link');

	&:focus-visible {
		background-color: unset;
		outline: none;
	}
}
