@use 'global-styles/marble/base/base';

.main {
	max-width: 100%;
	min-height: 100vh;
	padding: var(--spacing-xs) var(--spacing-component-margin) 0;
}

.title {
	font-family: var(--austin);
	font-size: var(--textsize-xl);
	font-weight: 500;
	margin-bottom: var(--spacing-micro);
}

.barWrapper {
	display: flex;
	flex-direction: row;
	gap: 4px;
}

.sectionTitle {
	font-size: var(--textsize-s);
	font-weight: 600;
}

.controlsSection {
	margin-bottom: var(--spacing-xxs);
}

.showOnly {
	padding-bottom: var(--spacing-xxs);
}

.totalResults {
	font-size: var(--textsize-s);
	font-weight: 600;
	line-height: 1;
	min-height: 1em;
	min-width: 280px;
}

.sortResults {
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	gap: var(--spacing-micro);
	justify-content: space-between;
	margin-bottom: var(--spacing-xxs);
}

.sortControl {
	align-items: center;
	display: flex;
	grid-column-gap: var(--spacing-micro);
	justify-content: flex-end;
}

.noResults {
	font-size: var(--textsize-m);
	font-weight: 600;
	padding: var(--spacing-m);
	text-align: center;
}

.facetWrapper {
	display: flex;
	flex-wrap: wrap;
	gap: var(--spacing-xxs);
	margin-bottom: var(--spacing-xxs);
}

.facetContainer {
	color: var(--color-grey-900);
	flex-basis: 100%;
	flex-grow: 1;
	flex-shrink: 0;
	@media (min-width: base.$breakpoint-m) {
		flex-basis: 20%;
	}
}

.facet {
	font-size: var(--textsize-xxs);
}

.facet-option {
	background-color: transparent;
	cursor: default;
	padding: 6px var(--spacing-micro);

	&.is-focused,
	&:active,
	&:hover,
	&:focus {
		background-color: var(--color-focus-state-background);
	}
}


.showWrapper {
	display: flex;
	flex-wrap: wrap;
	grid-column-gap: var(--spacing-xxs);
	grid-row-gap: var(--spacing-micro);
	list-style: none;
	margin: 0;
	padding: 0;
}

.showOption {
	align-items: center;
	cursor: pointer;
	display: flex;
	position: relative;
	white-space: nowrap;
}

.showCheckbox {
	accent-color: var(--color-grey-900);
	height: initial;
	margin-right: 5px;
	padding: 4px;
}

.activeFilters {
	align-items: flex-start;
	display: flex;
	flex-wrap: wrap;
	gap: var(--spacing-micro);

	&:not(:empty) {
		margin-bottom: var(--spacing-xxs);
	}
}

.hidden-input {
	display: none;
}

.rttWrapper {
	display: flex;
	justify-content: center;
	padding: var(--spacing-micro);
}
