.hours {
	align-items: center;
	display: flex;
	font-weight: 600;
	gap: 4px;
	margin-bottom: var(--spacing-micro);
}

.open {
	color: var(--color-green-good-news-400);
}

.closed {
	color: var(--color-met-red-dark);
}
