@use 'global-styles/marble/base/base.scss';

$background-color: var(--color-grey-050);

.content {
	display: flex;
	flex-direction: column;
	overflow: auto;

	@media (orientation: landscape) {
		align-items: center;
		flex-direction: row-reverse;
		grid-gap: var(--spacing-xxs);
		height: 100%;
		justify-content: space-between;
		margin: var(--spacing-xxs) var(--spacing-xxs) 0;
		overflow: auto;
		padding: var(--spacing-xxs) var(--spacing-xxs) 0;
	}
}

.title {
	margin-bottom: var(--spacing-micro);
}

.imageWrapper {
	align-items: center;
	background-color: $background-color;
	display: flex;
	flex-basis: 100%;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	padding-bottom: var(--spacing-s);

	@media (orientation: landscape) {
		padding-bottom: var(--spacing-m);
		position: sticky;
		top: 0;
	}

	img {
		align-self: center;
		height: auto;
		max-height: 100%;
		max-width: 100%;
		min-width: 0;
		object-fit: contain;
	}
}

.imageWrapperNoImage {
	align-items: flex-start;
	justify-content: start;
}

.text {
	max-height: none;
	padding: var(--spacing-xxs) var(--spacing-xxs) var(--spacing-m);

	@media (orientation: landscape) {
		flex-basis: 33.3%;
		flex-grow: 0;
		flex-shrink: 0;
		height: 100%;
		padding: 0;

		&:empty {
			flex-basis: 0;
		}

		&::after {
			content: '';
			display: block;
			height: var(--spacing-xs);
		}
	}
}

.attribution {
	font-size: var(--textsize-xs);
	font-weight: 500;
	margin-bottom: var(--spacing-xs);
}

.materials {
	margin-bottom: var(--spacing-xs);
}

.statement {
	margin-bottom: var(--spacing-xs);
}

.statementHeader {
	font-size: var(--textsize-m);
	font-weight: 600;
	line-height: 1.1;
}

.noImageSvg {
	color: var(--color-grey-400);
	display: block;
	height: 78px;
	margin: auto;
	width: 150px;
}

.noImageContainer {
	background-color: var(--color-grey-050);
	display: flex;
	min-height: calc(80vh - 35px);
	width: 100%;
}


.thumbnails {
	display: flex;
	gap: var(--spacing-micro);
	margin-top: var(--spacing-micro);
}

.thumbnail {
	max-width: 150px;

	&:hover {
		cursor: pointer;
	}
}
