.container {
	display: flex;
	flex-direction: column;
	gap: var(--spacing-micro);
	margin: var(--spacing-micro) auto;
	max-width: var(--max-width-unclamped);
}

.title {
	color: var(--color-met-red-medium);
	font-size: var(--textsize-m);
	font-weight: 500;
	line-height: 1.3;
}
