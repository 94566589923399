@mixin chevron($color: '%23333') {
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' style='transform:rotate(90deg)' viewBox='0 0 48 48'%3E%3Cpath fill='#{$color}' stroke='#{$color}' stroke-width='2.5' d='M14 8.223 16.077 6 35 24.011 16.079 42 14 39.777l16.888-15.813L14 8.224Z'/%3E%3C/svg%3E");
}

.container {
	border: 1px solid var(--color-border);
	border-radius: 4px;
	margin: var(--spacing-xxs) 0;
	padding: var(--spacing-xs);
}

.select {
	@include chevron();
	appearance: none;
	background-position: 95% 50%;
	background-repeat: no-repeat;
	background-size: 0.7em;
	line-height: 1.5555;
	position: relative;
	text-align: center;
	text-align-last: center;

	&:focus,
	&:hover {
		@include chevron(white);
	}
}

.printStatusMessage {
	font-weight: 500;
	margin-bottom: var(--spacing-micro);
}
