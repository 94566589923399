@use 'global-styles/marble/base/base.scss';

.card {
	display: flex;
	flex-basis: 0;
	flex-direction: column;
	flex-grow: 1;
	flex-shrink: 1;
	row-gap: var(--spacing-micro);
}

.titleLink {
	text-decoration: none;

	&:hover,
	&:focus {
		text-decoration: underline;
	}
}
