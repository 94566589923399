@use 'global-styles/marble/base/base';

.maipMastHead {
	background-color: var(--color-grey-050);
}

.maipHeader {
	background-color: var(--color-grey-100);
}

.maipControls {
	margin: 0 var(--spacing-component-margin) var(--spacing-xxs);
	padding-bottom: var(--spacing-s);
}

.maipSearch {
	display: flex;
	margin-bottom: var(--spacing-xs);
}

.maipResults {
	margin: var(--spacing-xxs) 0 var(--spacing-s);
}

.maipContainer {
	margin-bottom: var(--spacing-m);
	margin-left: auto;
	margin-right: auto;
	max-width: var(--productive-width-max);
}

.maipActiveFilters {
	height: var(--spacing-xs);
	margin: var(--spacing-xxs) auto;
	max-width: var(--productive-width-max);
}

.legend {
	font-weight: 600;
	margin: var(--spacing-micro) 0;
}

.maipNoResultsTitle {
	color: var(--color-met-red-medium);
	font-weight: 500;
	margin-bottom: var(--spacing-micro);
}

.maipLink {
	white-space: nowrap;
}
