@use 'global-styles/marble/base/base.scss';

.wrapper {
	overflow: auto;
	width: 100%;

	&::-webkit-scrollbar {
		display: none;
	}
}

.fieldset {
	border-bottom: 1px solid var(--color-border);
	display: inline-flex;
	margin: 0 var(--spacing-component-margin);
	width: var(--productive-width-max);
}

.tab {
	border-radius: 20px 20px 0 0;
	position: relative;

	&:has(:focus-visible) {
		background-color: var(--color-focus-state-background);
	}
}

.tabText {
	padding: 0 var(--spacing-xxs);
}

.input {
	appearance: none;
	border: 0;
	border-radius: 0;
	bottom: 0;
	left: 0;
	margin: 0;
	max-height: 100%;
	opacity: 0;
	position: absolute;
	width: 100%;
	z-index: base.z('negative');
}

.label {
	color: var(--color-text);
	cursor: pointer;
	font-size: var(--textsize-xl);
	font-weight: 500;
	text-decoration: none;
	white-space: nowrap;
	
	&.small {
		font-size: var(--textsize-m);
	}
}

.input:not(:checked) {
	&:focus + .label,
	&:hover + .label {
		color: var(--color-link);
	}

	+ label {
		color: var(--color-text);
	}
}

.input:checked + .label {
	border-bottom: 5px solid var(--color-active-tab);
	color: var(--color-link);
	display: inline-block;
}

.input:focus + .label,
.input:hover + .label {
	color: var(--color-link);
}

// Vertical and Horizontal tabs look the same below the breakpoint
@media only screen and (min-width: base.$breakpoint-m) {
	.vertical {
		border-right: 1px solid var(--color-divider);
		flex: 0 0 25%;

		.fieldset {
			border-bottom: 0;
			flex-direction: column;
			margin: 0;
			width: 100%;
		}

		.label {
			display: block;
			padding: var(--spacing-micro) 0;
			white-space: normal;
			width: 100%;
		}

		.input:checked + .label {
			background-color: var(--color-grey-050);
			border-bottom: 0;
			border-right: 5px solid var(--color-met-red-medium);
			color: var(--color-text);
			display: inline-block;
		}

		.input:focus + .label {
			background-color: var(--color-focus-state-background);
		}
	}
}
