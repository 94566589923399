.sponsorLogo {
	font-size: var(--textsize-xs);
	font-weight: 500;
	margin: 0 auto;
	max-width: 150px;
	text-align: left;
	width: 100%;

	svg {
		height: 100%;
		width: 100%;
	}
}

.personCardContainer {
	display: flex;
	flex-direction: column;
	margin-bottom: var(--spacing-xs);
	row-gap: var(--spacing-s);
}

.heroText {
	color: var(--color-grey-700);
}

.sponsors {
	color: var(--color-grey-700);
	font-size: var(--textsize-xs);
}
