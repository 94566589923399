@use 'global-styles/marble/base/base.scss';

.clampedMediaWrapper {
	margin: 0 var(--spacing-component-margin);
	position: relative;
	
	@media screen and (min-width: base.$breakpoint-m) {
		margin: 0 auto;
		width: min(60vw, 1440px);
	}
}

.clampedVideoContainer {
	aspect-ratio: 16 / 9;
	width: 100%;
}

.textWrapper {
	display: flex;
	justify-content: center;
	margin: var(--spacing-s) auto;
	max-width: var(--productive-width-max);
	width: min(var(--line-length-comfortable), var(--productive-width-max));
}

.toolTip {
	bottom: var(--spacing-xxs);
	left: var(--spacing-xxs);
	position: absolute;
}
