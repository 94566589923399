@use 'global-styles/marble/base/base.scss';

.wrapper {
	display: flex;
	flex-direction: column;
}

.bodyWrapper {
	margin: var(--spacing-xs) var(--spacing-component-margin) 0;
}

.divider {
	border-bottom: 1px solid var(--color-divider);
	padding-bottom: var(--spacing-xs);
}

// Vertical and Horizontal tabs look the same below the breakpoint
@media only screen and (min-width: base.$breakpoint-m) {
	.vertical {
		flex-direction: row;
		gap: var(--spacing-xs);
		margin: 0 var(--spacing-component-margin);

		.bodyWrapper {
			border-bottom: 0;
			margin: 0 0 var(--spacing-xs) 0;
		}

		.divider {
			padding: 0;
		}
	}
}
