@use 'global-styles/marble/base/base.scss';

.content {
	display: flex;
	flex-direction: column;
	gap: var(--spacing-xxs);
	overflow: auto;

	@media (orientation: landscape) {
		align-items: center;
		flex-direction: row-reverse;
		height: 100%;
		justify-content: space-between;
		margin: var(--spacing-xxs) var(--spacing-xxs) 0;
		overflow: auto;
		padding: var(--spacing-xxs) var(--spacing-xxs) 0;
	}
}

.title {
	font-family: var(--austin);
}

.imageWrapper {
	align-items: center;
	display: flex;
	flex-basis: 100%;
	flex-direction: column;
	gap: var(--spacing-xxs);
	height: 100%;
	justify-content: center;

	@media (orientation: landscape) {
		padding-bottom: var(--spacing-l);
		position: sticky;
		top: 0;
	}

	img {
		align-self: center;
		height: auto;
		max-height: 100%;
		max-width: 100%;
		min-width: 0;
	}
}

.noImageContainer {
	background-color: var(--color-grey-050);
	display: flex;
	min-height: calc(80vh - 35px);
	width: 100%;
}

.thumbnails {
	display: flex;
	gap: var(--spacing-micro);
	margin-top: var(--spacing-micro);
}

.thumbnail {
	max-width: 150px;
	
	&:hover {
		cursor: pointer;
	}
}

.textWrapper {
	max-height: none;
	padding: var(--spacing-xxs) var(--spacing-xxs) 0;

	@media (orientation: landscape) {
		flex-basis: 33.3%;
		flex-grow: 0;
		flex-shrink: 0;
		height: 100%;
		padding: 0;

		&:empty {
			flex-basis: 0;
		}
	}
}

.text {
	font-size: var(--textsize-xs);
	padding-bottom: var(--spacing-l);
}

.label {
	margin-bottom: var(--spacing-xxs);
}

.attribution {
	font-size: var(--textsize-xs);
	font-weight: 500;
	margin-bottom: var(--spacing-xs);
}

