@use 'global-styles/marble/base/base.scss';

.nav {
	padding-top: var(--spacing-xxs);
}

.jumpText,
.link {
	text-decoration: none;
	white-space: noWrap;
}

.link {
	@include base.button-reset();
	color: var(--color-link);
	font-size: 1em;
	font-weight: 600;
}

.jumpText {
	font-weight: 400;
}
