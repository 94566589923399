.container {
	margin: var(--spacing-xxs) 0 var(--spacing-s);
}

.filters {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	grid-column-gap: var(--spacing-xs);
}

.filterSetHeading {
	display: block;
}

.filter {
	align-items: center;
	display: flex;
	flex-direction: row;
	grid-column-gap: 4px;
	line-height: 0;
	margin-bottom: var(--spacing-xxs);
	position: relative;
}

.filterInput {
	cursor: pointer;
}

.filterLabel {
	column-gap: 10px;
	cursor: pointer;
	display: flex;
	font-weight: 400;
}

.legend {
	font-weight: 600;
}
