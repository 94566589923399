@use 'global-styles/marble/base/base.scss';

.header {
	background-color: var(--color-site-background);
	display: flex;
	flex-direction: column;

	&.sideBySide {
		@media screen and (min-width: base.$breakpoint-m) {
			flex-direction: row;
			min-height: 480px;
		}
		@media screen and (min-width: 1440px) {
			min-height: 33.3vw;
		}
	}
}

.sideBySide img {

	@media screen and (min-width: base.$breakpoint-m) {
		max-height: 100vh;
		width: auto;
	}

	@media screen and (min-width: 1440px) {
		height: calc(33.3vw - (var(--spacing-xs)*2));
	}
}

.headerBorder {
	border-bottom: 1px solid var(--color-divider);
	border-top: 1px solid var(--color-divider);
}

.imageWrapper {
	align-items: center;
	aspect-ratio: 1;
	background-color: var(--color-component-background);
	display: flex;
	flex-basis: 50%;
	flex-grow: 1;
	flex-shrink: 0;
	justify-content: center;
	padding: var(--spacing-xs);
	position: relative;

	@media screen and (min-width: base.$breakpoint-m) {
		aspect-ratio: auto;
	}
}


.fill {
	padding: 0;
	position: relative;
}

.toah {
	display: flex;
	flex-direction: column;
}

.textWrapper {
	align-self: center;
	display: flex;
	justify-content: center;
	margin: var(--spacing-s) auto;
	max-width: var(--productive-width-max);
	width: min(var(--line-length-comfortable), var(--productive-width-max));
	
	@media screen and (min-width: base.$breakpoint-m) {
		flex-basis: 50%;
		margin: var(--spacing-s);
	}
}

.toolTip {
	bottom: var(--spacing-xxs);
	left: var(--spacing-xxs);
	position: absolute;
}

