@use 'global-styles/marble/base/base.scss';

.title {
	margin-bottom: var(--spacing-micro);
}

.addressText {
	display: flex;
	flex-direction: column;
	font-size: var(--textsize-xs);
	gap: var(--spacing-xxs);
}
