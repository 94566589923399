
//TODO find a way to not dupe these from accordion.


.summary {
	align-items: center;
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	margin: var(--title-padding) 0;

	&::after {
		content: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M18 9.7875V8.2125H9.7875V0H8.2125V8.2125H0V9.7875H8.2125V18H9.7875V9.7875H18Z' fill='%23ffffff'/%3e%3c/svg%3e");
		height: var(--spacing-xs);
		width: var(--spacing-xs);
	}

	&::-webkit-details-marker {
		display: none;
	}

	[open] &::after {
		content: url("data:image/svg+xml,%3csvg width='32' height='28' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect y='10' width='18' height='1.5' fill='%23ffffff'/%3e%3c/svg%3e");
	}

	&:focus-visible {
		background-color: var(--color-focus-state-background);
		outline: 4px solid var(--color-focus-state-background);
	}
}

.heading {
	padding: var(--spacing-xs) var(--spacing-component-margin);

	h2 {
		color: inherit;
	}
}
