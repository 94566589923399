@use 'global-styles/marble/base/base';

.selectWrapper {
	--select-background: var(--color-white);
	background-color: var(--select-background);
	border: 1.5px solid var(--color-border);
	border-radius: base.$radius-sharp;
	display: flex;
	min-width: 100px;
	position: relative;

	&:focus-visible,
	&:hover {
		border-color: var(--color-border-active);
	}

	&:focus-within {
		--select-background: var(--color-focus-state-background);
		border-color: var(--color-focus-state-outline);
	}

	&::after {
		background-color: var(--select-background);
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' style='transform:rotate(90deg)' viewBox='0 0 48 48'%3E%3Cpath fill='%23333' stroke='%23333' stroke-width='2.5' d='M14 8.223 16.077 6 35 24.011 16.079 42 14 39.777l16.888-15.813L14 8.224Z'/%3E%3C/svg%3E");
		background-position: 0 50%;
		background-repeat: no-repeat;
		background-size: 0.7em;
		border-radius: base.$radius-sharp;
		content: '';
		height: 100%;
		pointer-events: none;
		position: absolute;
		right: 12px;
		width: 12px;
	}
}

.select {
	appearance: none;
	background-color: transparent;
	border: 0;
	color: var(--color-link);
	cursor: pointer;
	font-size: 14px;
	font-weight: 500;
	height: 100%;
	padding: var(--spacing-micro) 36px var(--spacing-micro) var(--spacing-micro);
	text-overflow: ellipsis;
	width: 100%;

	&:focus {
		outline: 0;
	}
}
