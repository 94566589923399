.badge {
	background-color: var(--color-white-opaque-85);
	border: 0.5px solid currentColor;
	border-radius: 500px;
	display: inline-flex;
	flex-shrink: 1;
	font-size: var(--textsize-xxs);
	font-weight: 500;
	gap: 6px;
	line-height: 1.1;
	padding: 8px var(--spacing-micro);
	text-transform: uppercase;
}
