@use 'global-styles/marble/base/base.scss';

.audioGuide {
	align-items: center;
	border-bottom: 1px solid var(--color-divider);
	display: flex;
	flex-direction: column;
	padding: 0 var(--spacing-component-margin) var(--spacing-s);
	text-align: center;
}

.header {
	--color-text-header: var(--color-white);
	background: var(--color-welcome-header);
	color: var(--color-white);
	display: inline-block;
	font-weight: 600;
	width: 100%;
}

.body {
	background: var(--color-white);
	color: var(--color-grey-900);
	padding: var(--spacing-s) 0 0;
}

.eventsBody {
	background: var(--color-white);
	color: var(--color-grey-900);
	display: inline-block;
	padding: 0;
	width: 100%;
}

.promoSection {
	margin: var(--spacing-s) var(--spacing-component-margin);
	text-align: center;
}

.subsection {
	margin: var(--spacing-s) var(--spacing-component-margin);
}

.logo {
	height: var(--spacing-m);
	margin-bottom: var(--spacing-xxs);
	width: var(--spacing-m);
}

.stickyGrid {
	background: var(--color-welcome-header);
	display: grid;
	grid-auto-columns: 1fr min-content;
	grid-auto-flow: row;

	details {
		grid-column: span 2;
	}

	section {
		grid-column: span 2;
	}

	section:first-of-type {
		grid-column: 1;
		grid-row: 1;
	}
}

.stickyLinks {
	align-self: start;
	background: transparent;
	display: flex;
	flex-direction: column;
	gap: var(--spacing-micro);
	grid-column: 2 !important;
	grid-row: 1;
	margin-top: var(--spacing-s);
	position: sticky;
	right: var(--spacing-component-margin);
	top: var(--spacing-s);
	z-index: base.z('sticky-element');
}

.stickyLink {
	border-radius: 50%;
	box-shadow: base.$shadow-passive;
	height: 60px;
	width: 60px;

	&:hover,
	&:focus {
		box-shadow: base.$shadow-active;
	}
}

.linkSvg {
	height: 100%;
	width: 100%;
}

.hours {
	min-height: 1.75em;
}

.headphonesIcon {
	margin: 0 0.2em;
}

.audioSearch {
	margin-bottom: var(--spacing-xxs);
}
