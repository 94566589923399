.availabilityMessage {
	font-weight: 600;
	margin-top: 8px;
}

.availabilityMessageOpen {
	color: var(--color-green-good-news-400);
}

.availabilityMessageClosed {
	color: var(--color-met-red);
}

.buttonWrapper {
	width: 100%;
}
