@use 'global-styles/marble/base/base';

.container {
	height: 40px;
	position: relative;
	width: 40px;
}

.letterFilter {
	appearance: none;
	border: 0;
	border-radius: 0;
	bottom: 0;
	left: 0;
	margin: 0;
	max-height: 100%;
	opacity: 0;
	position: absolute;
	z-index: base.z('negative');
}

.letterFilterLabel {
	align-items: center;
	background: var(--color-white);
	border: 1px solid var(--color-border);
	border-radius: base.$radius-sharp;
	color: var(--color-grey-900);
	display: inline-flex;
	height: 100%;
	justify-content: center;
	line-height: 1;
	user-select: none;
	width: 100%;

	:checked + & {
		background: var(--color-grey-900);
		border: 2px solid var(--color-grey-900);
		color: var(--color-white);
	}

	:checked:focus + & {
		background: var(--color-grey-900);
		border: 2px solid var(--color-grey-900);
		color: var(--color-white);
		outline: 1px solid var(--color-grey-900);
		outline-offset: 1px;
	}

	:disabled + &,
	:disabled + &:hover {
		background-color: var(--color-grey-200);
		border-color: var(--color-grey-700);
		color: var(--color-grey-700);
		cursor: not-allowed;
	}

	:focus-visible + & {
		background-color: var(--color-focus-state-background);
		border-color: var(--color-focus-state-outline);
		color: var(--color-focus-state-outline);
	}

	&:hover {
		border-color: var(--color-border-active);
		cursor: pointer;
	}
}
