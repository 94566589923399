.container {
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	gap: var(--spacing-xxs);
}

.accordionContainer {
	align-self: stretch;
}

.support {
	font-size: var(--textsize-xs);
}

.title {
	margin-bottom: var(--spacing-micro);
}
