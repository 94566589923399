@use 'global-styles/marble/base/base.scss';

.contentSplit {
	display: grid;
	grid-template-columns: [page-start] var(--spacing-horizontal) //Left Margin.
		[content-start] 0
		[main-start] minmax(0, 2fr) //Width of Main column
		[main-end] 0
		[column-divide] var(--spacing-l) //Width of Main/Aside Gap
		[aside-start] minmax(0, 1fr) //Width of Aside.
		[aside-end] 0
		[content-end] 0
		[page-end] var(--spacing-horizontal); //Right Margin
	grid-template-rows:
		[title] auto
		[main] auto
		[column-divide] var(--spacing-m)
		[aside] auto
		[divide] auto;
	margin-bottom: var(--spacing-vertical);
	margin-top: var(--spacing-vertical);

	@media screen and (min-width: base.$breakpoint-m) {
		grid-template-rows:
			[title] auto
			[main] auto
			[column-divide] auto
			[aside] auto
			[divide] auto;
	}

	&.withVerticalCenter {
		align-items: center;
	}

	&.inverted {
		grid-template-columns: [page-start] var(--spacing-horizontal) //Left Margin.
			[content-start] 0
			[aside-start] minmax(0, 1fr) //Width of Aside.
			[aside-end] 0
			[column-divide] var(--spacing-l) //Width of Main/Aside Gap
			[main-start] minmax(0, 2fr) //Width of Main column
			[main-end] 0
			[content-end] 0
			[page-end] var(--spacing-horizontal); //Right Margin
		grid-template-rows: [title] auto 
			[aside] auto 
			[column-divide] var(--spacing-m) 
			[main] auto 
			[divide] auto;

		@media screen and (min-width: base.$breakpoint-m) {
			grid-template-rows:
				[title] auto
				[aside] auto
				[column-divide] auto
				[main] auto
				[divide] auto;
		}
	}

	&.invertDesktopOnly {
		@media screen and (min-width: base.$breakpoint-m) {
			grid-template-columns: [page-start] var(--spacing-horizontal) //Left Margin.
				[content-start] 0 [aside-start] minmax(0, 1fr) //Width of Aside.
				[aside-end] 0 [column-divide] var(--spacing-l) //Width of Main/Aside Gap
				[main-start] minmax(0, 2fr) //Width of Main column
				[main-end] 0 [content-end] 0 [page-end] var(--spacing-horizontal); //Right Margin
			grid-template-rows:
				[title] auto
				[aside] auto
				[column-divide]auto
				[main] auto
				[divide] auto;
		}
	}
}

.emptyAside {
	grid-template-rows: [title] auto [main] auto [column-divide] auto [aside] auto [divide] auto;
}

.title {
	grid-column-end: content-end;
	grid-column-start: content-start;
	grid-row: title;
	margin-bottom: var(--spacing-xxs);
}

.main,
.aside {
	grid-column-end: content-end;
	grid-column-start: content-start;

	&:empty {
		display: none;
	}
}

.main {
	grid-row-start: main;
	@media screen and (min-width: base.$breakpoint-m) {
		grid-column-end: main-end;
		grid-column-start: main-start;
	}
}

.aside {
	grid-row-start: aside;
	@media screen and (min-width: base.$breakpoint-m) {
		grid-column-end: aside-end;
		grid-column-start: aside-start;
		grid-row-start: main;
	}
}

.divider {
	color: var(--color-divider);
	grid-column-end: content-end;
	grid-column-start: content-start;
	grid-row: divide;
	height: 2px;
	margin-top: var(--spacing-vertical);
	width: 100%;
}
