@use 'global-styles/marble/base/base.scss';

.wrapper {
	column-gap: var(--spacing-xxs);
	display: flex;
	flex-direction: column;
	margin: var(--spacing-m) var(--spacing-component-margin);
	row-gap: var(--spacing-xxs);

	@media screen and (min-width: base.$breakpoint-m) {
		flex-direction: row;
	}
}

.header {
	@media screen and (min-width: base.$breakpoint-m) {
		flex: 0 0 25%;
	}
}

.main {
	@media screen and (min-width: base.$breakpoint-m) {
		flex: 0 0 calc(75% - var(--spacing-xs));
	}
}
