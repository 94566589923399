@use 'global-styles/marble/base/base';
@forward 'audio-controls.module.scss';
@forward './playlist.module.scss';
@forward './mini-player.module.scss';
@forward './micro-player.module.scss';
$cq-audio-player: 300px;

.audioPlayer {
	background-color: var(--color-white);
	border-radius: base.$radius-blunt;
	box-shadow: base.$shadow-passive;
	color: var(--color-grey-900);
	container-name: audioPlayer;
	container-type: inline-size;
	overflow: hidden;
	position: relative;
}

.mediaSection {
	display: flex;
	flex-direction: column;
	padding: var(--spacing-xxs);
	position: relative;
	@container audioPlayer (width > #{$cq-audio-player}) {
		padding: 0;
	}
}

.badgeContainer {
	@container audioPlayer (width > #{$cq-audio-player}) {
		margin: var(--spacing-xxs) var(--spacing-xxs) 0;
		max-width: 100%;
	}
}

.imageSection {
	margin: 0 0 var(--spacing-xxs);
	width: 100%;
	@container audioPlayer (width > #{$cq-audio-player}) {
		flex-basis: 0;
		flex-grow: 1;
		flex-shrink: 0;
		margin: 0;
	}
}

.imageWrapper {
	background-color: var(--color-grey-800);
	height: 0;
	overflow: hidden;
	padding-top: 100%;
	position: relative;
	@container audioPlayer (width > #{$cq-audio-player}) {
		min-width: 200px;
	}

	&:empty {
		display: none;
	}

	&::after {
		background-color: var(--color-white);
		bottom: 0;
		content: '';
		left: 0;
		opacity: 0.5;
		position: absolute;
		right: 0;
		top: 0;
	}
}

.coverImage {
	height: 100%;
	left: 0;
	object-fit: cover;
	position: absolute;
	top: 0;
	width: 100%;
}

.body {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	overflow: hidden;
	@container audioPlayer (width > #{$cq-audio-player}) {
		padding: var(--spacing-xxs);
	}
}

.title {
	font-size: var(--textsize-s);
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.subtitle {
	font-size: var(--textsize-xs);
	font-weight: 400;
	margin-bottom: var(--spacing-micro);
}

.controlsWrapper {
	margin-top: auto;
	width: 100%;
	
}

.progressBar {
	background-color: var(--color-grey-200);
	border-radius: 50px;
	height: 6px;
	width: 100%;
}

.audioElement {
	display: none;
}

.transcriptSection {
	border-top: 1px solid var(--color-grey-100);
}

.transcriptTrue {
	display: block;
}

.transcriptFalse {
	display: none;
}

.transcriptWrapper {
	background-color: var(--color-grey-100);
	max-height: 0;
	overflow: auto;
	transition: max-height 0.3s;

	.transcriptIsOpen & {
		max-height: 350px;
	}
}

.transcript {
	padding: var(--spacing-xxs);
}

.transcriptToggle {
	align-items: flex-start;
	cursor: pointer;
	display: flex;
	font-size: var(--textsize-xs);
	font-weight: 500;
	line-height: 1;
	padding: var(--spacing-xxs);
	text-decoration: none;
}

.transcriptToggle:focus,
.transcriptToggle:hover {
	color: var(--color-purple);

	@media (prefers-color-scheme: dark) {
		color: var(--color-purple);
	}
}


.transcriptToggleIcon {
	height: 1em;

	.transcriptIsOpen & {
		align-self: flex-end;
		display: inline-block;
		transform: rotate(180deg);
	}
}

.transcriptToggleText {
	margin-left: var(--spacing-micro);
}

//MODES

//Inverted
.audioPlayer { // sass-lint:disable-line no-mergeable-selectors
	@media (prefers-color-scheme: dark) {
		--color-text: var(--color-white);
		--color-text-header: var(--color-white);
		--color-link: var(--color-white);
		background-color: var(--color-grey-900);
		color: var(--color-white);
		color-scheme: dark;
	}
}

.transcriptWrapper { // sass-lint:disable-line no-mergeable-selectors
	@media (prefers-color-scheme: dark) {
		background-color: var(--color-grey-800);
		color: var(--color-white);
	}
}

.progressBar { // sass-lint:disable-line no-mergeable-selectors
	@media (prefers-color-scheme: dark) {
		filter: invert(1);
	}
}

.imageWrapper {  // sass-lint:disable-line no-mergeable-selectors
	@media (prefers-color-scheme: dark) {
		background-color: var(--color-grey-700);

		&::after {
			background-color: var(--color-grey-700);
		}
	}
}

.currentTime,
.remainingTime {
	min-width: 4ch;
}

.remainingTime {
	text-align: right;
}
