.ul,
.ol {
	list-style: unset;
	margin-bottom: var(--spacing-paragraph);
	margin-left: 1em;

	.ul {
		list-style: circle;
		margin-bottom: var(--spacing-paragraph);
		margin-left: 2em;
		margin-top: var(--spacing-paragraph);
	}

	&:last-child {
		margin-bottom: 0;
	}
}

