@use 'global-styles/marble/base/base';

.filters {
	display: flex;
	flex-wrap: wrap;
	gap: 2px;
}

.legend {
	font-weight: 600;
	margin: var(--spacing-micro) 0;
}
