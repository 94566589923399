
@use 'global-styles/marble/base/base.scss';
$breakpoint-location-card: 700px; //TODO can we switch to m or s?

.buildingCard {
	background-color: var(--color-component-background);
	display: flex;
	flex-basis: var(--max-width-unclamped);
	flex-direction: column;
	flex-shrink: 0;
	margin-bottom: 10px; //For shadow.
	transition: 0.2s all;

	&:hover,
	&:focus-within {
		box-shadow: base.$shadow-passive;
	}

	@media screen and (min-width: $breakpoint-location-card) {
		flex-basis: 50%;
		flex-grow: 1;
		flex-shrink: 1;
	}
}

.imageWrapper {
	aspect-ratio: 3 / 2;
	display: block;
	flex-shrink: 0;
	line-height: 0;
	position: relative;
}

.badgeWrapper {
	background-color: var(--color-site-background);
	border-radius: 50px;
	bottom: var(--spacing-micro);
	font-weight: 500;
	left: var(--spacing-micro);
	position: absolute;
}

.body {
	display: flex;
	flex-direction: column;
	height: 100%;
	padding: var(--spacing-xxs);
}

.title {
	@include base.typography-h3;
	margin-bottom: var(--spacing-micro);
}

.link {
	border-bottom: 0;
	display: block;
	position: relative;
	text-decoration: none;

	&:hover,
	&:focus,
	.imageWrapper:hover+.body & {
		text-decoration: underline;
	}
}

.info {
	font-size: var(--textsize-xs);
	margin-bottom: var(--spacing-xxs);
}

.description {
	margin-bottom: var(--spacing-xxs);
}

.time {
	line-height: 1;
	margin-bottom: var(--spacing-micro);
}

.label {
	color: var(--color-text-header);
	font-weight: 600;
}

.daysClosed {
	color: var(--color-text);
	font-size: var(--textsize-xs);
	line-height: 1.5;
	margin-top: auto;
}
