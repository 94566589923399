@use 'global-styles/marble/base/base.scss';

.equalWidthColumns {
	display: grid;
	gap: var(--spacing-m);
	grid-auto-columns: 1;
	grid-auto-columns: minmax(0, 1fr);
	@media screen and (min-width: base.$breakpoint-m) {
		grid-auto-flow: column;
	}
}

.withDivider {
	border-bottom: 1px solid var(--color-divider);
	margin-bottom: var(--spacing-m);
	padding-bottom: var(--spacing-m);
}

.fullWidth {
	gap: var(--spacing-xs);
}
