@use 'global-styles/marble/base/base';

.playlist {
	background: var(--color-grey-050);
	border-bottom: var(--spacing-xxs) solid var(--color-grey-050); // hack to add bottom 'padding' for the overflow 👇
	max-height: 265px; //Madhav picked this.
	overflow: auto;
	padding: var(--spacing-xs) var(--spacing-xs) 0 var(--spacing-xs);

	&:empty {
		display: none;
	}

	@media (prefers-color-scheme: dark) {
		background: var(--color-grey-900);
		border-bottom: var(--spacing-xxs) solid var(--color-grey-900);
		color: var(--color-white);
	}
}

.playlistTitle {
	font-weight: 500;
}

.playlistTrack {
	align-items: center;
	border-bottom: 1px solid var(--color-divider);
	cursor: pointer;
	display: flex;
	font-size: var(--textsize-xs);
	font-weight: 500;
	opacity: 0.55;
	padding: var(--spacing-micro) 0;
	transition: opacity 0.1s;

	&.isActive {
		opacity: 1;
	}

	&:last-child {
		border-bottom: 0;
	}
}

.playlistTrackThumbnail {
	height: 2rem;
	margin-right: var(--spacing-micro);
	object-fit: cover;
	width: 2rem;
}

.playlistTrackTitle {
	flex-grow: 1;
}
