@use 'global-styles/marble/base/base.scss';

.page {
	background-color: var(--color-grey-900);
	color: var(--color-white);
	display: flex;
}

.imageWrapper {
	align-items: center;
	background-color: var(--color-black-opaque-50);
	display: flex;
	flex-basis: 40vw;
	flex-shrink: 0;
	height: var(--max-page-height);
	justify-content: center;
	position: relative;
}

.fill {
	min-width: 100%;
}

.fit {
	max-height: 100%;
	width: auto;
}

.question {
	font-size: var(--textsize-xl);
}

.text {
	display: flex;
	flex-basis: 60vw;
	flex-direction: column;
	flex-grow: 0;
	font-size: var(--textsize-m);
	height: var(--max-page-height);
	padding: var(--spacing-m) var(--spacing-m) var(--spacing-m) var(--spacing-l);
}

.headings {
	margin-bottom: var(--spacing-xxs);
}

.subTitle {
	font-size: var(--textsize-m);
	font-weight: 600;
	line-height: 1.1;
	margin-top: var(--spacing-micro);
}

.body {
	@include base.line-clamp(13);
	margin-bottom: var(--spacing-xxs);
	overflow: hidden;
}

.progressBars {
	display: flex;
	flex-wrap: wrap;
	gap: var(--spacing-micro);
	margin-bottom: var(--spacing-xxs);
}

.progressBar {
	background-color: var(--color-grey-700);
	display: inline-block;
	height: 6px;
	width: 40px;

	&.active {
		background-color: var(--color-white);
	}
}

.caption {
	backdrop-filter: blur(2px);
	background-color: var(--color-black-opaque-50);
	bottom: 0;
	font-size: var(--textsize-xxs);
	left: 0;
	padding: var(--spacing-xxs);
	position: absolute;
	right: 0;
}

.progressImages {
	justify-self: flex-end;
	margin-bottom: 0;
	margin-top: auto;
}

.thumbnailWrapper {
	height: 80px;
	opacity: 0.5;
	position: relative;
	width: 80px;

	&.active {
		border: 2px solid var(--color-text);
		opacity: 1;
	}
}
