@use 'global-styles/marble/base/base.scss';

.controls {
	align-items: center;
	display: flex;
	flex-direction: column;
	margin-top: var(--spacing-xxs);
}

.helperText {
	color: var(--color-grey-700);
}

.button {
	@include base.button-reset;
	align-items: center;
	display: flex;
	font-size: 1em;
	font-weight: 500;
	gap: 8px;

	&:hover {
		text-decoration: underline;
	}

	&:focus-visible {
		background-color: var(--color-focus-state-background);
		outline: 0;
		text-decoration: underline;
	}
}
