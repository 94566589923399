@use 'global-styles/marble/base/base.scss';

.videoBanner {
	align-items: center;
	display: flex;
	flex-direction: column;
	height: 500px;
	margin-bottom: var(--spacing-s);
	overflow: hidden;
	position: relative;
	width: 100%;

	@media screen and (min-width: base.$breakpoint-s) {
		max-height: calc(100vh - 168px);
	}

	@media screen and (min-width: base.$breakpoint-m) {
		height: calc(100vh - 268px);
		min-height: 500px;
	}

	&::after {
		background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.5) 100%);
		bottom: 0;
		content: '';
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		z-index: base.z('image-scrim');
	}
}

.vimeoContainer {
	box-sizing: border-box;
	height: 56.25vw;
	left: 50%;
	min-height: 100%;
	min-width: 100%;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	visibility: visible;
	width: 177.77777778vh;
	z-index: base.z('positive-default');
}

.image {
	align-self: flex-start;
	height: 100%;
	left: 0;
	object-fit: cover;
	position: absolute;
	top: 0;
	width: 100%;
}

.copy {
	color: var(--color-white);
	font-family: var(--austin);
	margin: auto;
	max-width: var(--productive-width-max);
	padding: var(--spacing-xxs) 0;
	text-align: center;
	z-index: base.z('copy-on-images');
}

.title {
	color: var(--color-white);
	font-size: var(--textsize-xxxl);
	font-weight: 500;
	letter-spacing: normal;
	margin-bottom: var(--spacing-micro);
}

.picture {
	height: 100%;
	position: absolute;
	width: 100%;
}

:global(.secondary).button { //Deprecated styles. Move away from this ASAP.
	box-shadow: inset 0 0 0 2px var(--color-white);
	color: var(--color-white);
	
	&:focus,
	&:hover {
		background-color: var(--color-white);
		color: var(--color-text);
	}
}
