@use 'global-styles/marble/base/base';

.pageControls {
	align-items: center;
	display: flex;
	gap: var(--spacing-micro);
	justify-content: center;
	margin-bottom: var(--spacing-xxs);
}

/* TODO: fix selector specificity / without this pagination buttons have min-width: 160px
This is the a classic case of us wanting to make these buttons 99% the same as a global style,
but with one line of CSS that doesn't play nice overriding the (min-width).
For the short term, it makes sense to override this here -
but long term I'm looking to remove the button styles as global CSS classes.
They are only there to support RTE styles easier but we are deprecating buttons in the RTE,
once we refactor the visit page we can clean up buttons
*/
:global(.secondary).paginationButton {
	height: var(--spacing-s);
	min-width: 0;
	width: var(--spacing-s);

	&:disabled:focus,
	&:disabled:hover,
	&:disabled {
		background-color: transparent;
		border: 0;
		opacity: 0.7;
		z-index: base.z('negative');
	}

	&:focus {
		background-color: var(--color-focus-state-background);
		outline-color: var(--color-focus-state-outline);
	}
}

.buttonSpacer {
	height: var(--spacing-s);
	opacity: 0;
	width: var(--spacing-s);
}
