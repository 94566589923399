.resultsCountSection {
	align-items: center;
	column-gap: var(--spacing-micro);
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;
	margin-bottom: var(--spacing-xxs);
	row-gap: var(--spacing-micro);
}

.sortByTitle {
	color: var(--color-text-header);
	font-weight: 400;
}
