@use 'global-styles/marble/base/sizes' as sizes;

.socialFollowText {
	margin-bottom: var(--spacing-micro);
}

.socialButtonsContainer {
	display: flex;
	gap: var(--spacing-micro);
}

.socialIcon {
	height: 24px;
	width: 24px;
}
