@use 'global-styles/marble/base/base.scss';

.js-iv__trigger {
	cursor: pointer;
}

.container {
	background-color: var(--color-component-background-2);
	bottom: 0;
	color: var(--color-text-color);
	display: none;
	flex-direction: column;
	left: 0;
	max-height: 100%;
	overflow: hidden;
	padding-bottom: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: base.z('object-viewer');

	@media (orientation: landscape) {
		border-radius: base.$radius-soft;
		bottom: var(--spacing-xxs);
		box-shadow: 0 0 0 var(--spacing-m) var(--color-black-transparency);
		left: var(--spacing-xxs);
		padding: var(--spacing-xxs) var(--spacing-xxs) 0;
		right: var(--spacing-xxs);
		top: var(--spacing-xxs);
	}

	&.isFullscreen {
		display: flex;
	}

	&::after {
		backdrop-filter: blur(2px);
		background-color: var(--color-white-opaque-70);
		border-top: 1px solid var(--color-divider);
		bottom: 0;
		content: '';
		height: var(--spacing-m);
		left: 0;
		pointer-events: none;
		position: absolute;
		right: 0;

		@media (orientation: landscape) {
			backdrop-filter: unset;
			background: linear-gradient(to top, var(--color-component-background-2) 20%, transparent 80%);
			border: 0;
			height: 115px;
		}
	}
}

.close {
	@include base.button-reset();
	align-self: flex-end;
	display: block;
	height: var(--spacing-xs);
	margin: var(--spacing-xxs) var(--spacing-xxs) var(--spacing-xxs) auto;
	padding: 4px;
	width: var(--spacing-xs);

	@media (orientation: landscape) {
		margin: 0 0 0 auto;
	}

	&:active,
	&:hover {
		opacity: 0.8;
	}

	svg {
		height: 100%;
		width: 100%;
	}
}

.arrow {
	@include base.button-reset();
	bottom: var(--spacing-micro);
	height: var(--spacing-xs);
	padding: 4px;
	position: absolute;
	text-align: center;
	width: var(--spacing-xs);
	z-index: base.z('positive-default');

	@media (orientation: landscape) {
		bottom: 50%;
	}

	&:active,
	&:hover {
		opacity: 0.8;
	}

	&.isDisabled {
		cursor: default;
		opacity: 0.2;
	}

}

.forward {
	right: var(--spacing-xxs);
	transform: rotate(180deg);

	@media (orientation: landscape) {
		right: var(--spacing-micro);
	}
}

.back {
	left: var(--spacing-xxs);

	@media (orientation: landscape) {
		left: var(--spacing-micro);
	}
}
