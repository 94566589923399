
@use 'global-styles/marble/base/base.scss';

.exhibitionCard {
	flex-shrink: 0;
	max-width: var(--exhibition-card-max-width);
	width: var(--exhibition-card-width);
}

.title {
	@include base.typography-h4;
	margin-bottom: 4px;
}

.status {
	@include base.typography-tag();
	margin-bottom: var(--spacing-micro);
	padding-top: var(--spacing-micro);
}

.figure {
	aspect-ratio: 16 / 9;
	box-sizing: content-box;
	display: flex;
	justify-content: center;
	margin-bottom: var(--spacing-micro);
	position: relative;
	width: 100%;
}

.image {
	min-width: 100%;
	object-fit: cover;
	overflow: hidden;
	transition: all 0.2s;
}

.meta {
	display: flex;
	flex-direction: column;
	flex-grow: 0;
	flex-wrap: wrap;
	font-size: var(--textsize-xs);
	justify-content: space-between;
	margin-top: auto;
}
