.introWrapper {
	align-items: center;
	display: flex;
}

.onLoanWrapper {
	color: var(--color-welcome-header);
	font-size: var(--textsize-xs);
	font-weight: 600;
}

.onLoanNotice {
	align-items: baseline;
	column-gap: 3px;
	display: flex;
}

.onLoanTitle {
	text-transform: uppercase;
}

.deaccessionWrapper {
	color: var(--color-welcome-header);
	font-size: var(--textsize-xs);
	font-weight: 600;
}

.deaccessionNotice {
	align-items: baseline;
	column-gap: 3px;
	display: flex;
}

.deaccessionTitle {
	text-transform: uppercase;
}

.deaccessionDate {
	font-weight: 600;
}

.regularTitle,
.longTitle {
	font-family: var(--austin);
	font-size: var(--textsize-xl);
	font-weight: 500;
	line-height: 1.05;
}

.veryLongTitle {
	font-family: var(--austin);
	font-size: var(--textsize-l);
	font-weight: 500;
	line-height: 1.2;
}

.metaWrapper {
	font-size: var(--textsize-xs);
}

.onViewWrapper {
	align-items: baseline;
	column-gap: 2px;
	display: flex;
	flex-direction: row;
	font-weight: 600;
	margin-top: var(--spacing-s);
}

.label {
	margin-top: var(--spacing-s);
}
