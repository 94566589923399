.wrapper {
	--thumbnail-size: 55px;
	margin-top: var(--spacing-xxs);
	@media (orientation: landscape) {
		--thumbnail-size: 85px;
	}
}

.button {
	cursor: pointer;
	opacity: 0.67;

	&:hover,
	&:focus {
		opacity: 0.8;
	}

	&:disabled {
		cursor: default;
		opacity: 1;
	}
}

.thumbnail {
	height: var(--thumbnail-size);
	object-fit: cover;
	width: var(--thumbnail-size);

	.button:disabled & {
		border: 3px solid var(--color-white);
	}
}

.noImage {
	background-color: var(--color-white);
	height: var(--thumbnail-size);
	object-fit: cover;
	padding: var(--spacing-xxs);
	width: var(--thumbnail-size);
}
