@use 'global-styles/marble/base/base.scss';

.body {
	align-self: center;
	background-color: var(--color-site-background);
	display: flex;
	flex-basis: 50%;
	flex-direction: column;
	flex-grow: 1;
	flex-shrink: 1;
	gap: var(--spacing-xxs);
	justify-content: center;
	padding: 0;
	text-align: center;
}

.type {
	font-size: var(--textsize-xxs);
	font-weight: 500;
	text-transform: uppercase;
}

.title {
	font-size: var(--textsize-xxl);
	margin-bottom: var(--spacing-micro);
}

.byLine {
	display: flex;
	flex-direction: column;
	font-size: var(--textsize-xs);
}

.description,
.date,
.duration,
.readingTime {
	color: var(--color-text);
}

.subtitle {
	color: var(--color-text);
	font-size: var(--textsize-m);
}

.seriesLink {
	text-decoration: none;
	
	&:hover,
	&:focus-visible {
		text-decoration: underline;
	}
}
