.attribution {
	color: var(--color-text);
	font-weight: 500;
}

.comingSoon {
	color: var(--color-text);
	
	img {
		filter: grayscale(0.5);
		opacity: 0.4;
	}
}
