
@use 'global-styles/marble/base/base.scss';

.details {
	border-bottom: 1px solid var(--color-border);
}

.summary {
	align-items: center;
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	margin: var(--title-padding) 0;

	&::after {
		content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' style='transform:rotate(90deg)' viewBox='0 0 48 48'%3E%3Cpath fill='%23333' stroke='%23333' stroke-width='2.5' d='M14 8.223 16.077 6 35 24.011 16.079 42 14 39.777l16.888-15.813L14 8.224Z'/%3E%3C/svg%3E");
		height: var(--spacing-xxs);
		width: var(--spacing-xxs);
	}

	&::-webkit-details-marker {
		display: none;
	}

	[open] &::after {
		content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' style='transform:rotate(270deg)' viewBox='0 0 48 48'%3E%3Cpath fill='%23333' stroke='%23333' stroke-width='2.5' d='M14 8.223 16.077 6 35 24.011 16.079 42 14 39.777l16.888-15.813L14 8.224Z'/%3E%3C/svg%3E");
	}

	&:focus-visible {
		background-color: var(--color-focus-state-background);
		outline: 4px solid var(--color-focus-state-background);
	}

	&:hover {
		text-decoration: underline;
	}
}
