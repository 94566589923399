@use 'global-styles/marble/base/base';
$cq-audio-controls: 300px;

.audioControls {
	display: flex;
	fill: currentColor;
	font-size: var(--textsize-xs);
	width: 100%;
}

.playWrapper {
	align-self: flex-start;
	flex-basis: var(--spacing-s);
	flex-shrink: 0;
	font-size: var(--textsize-l);
	height: 1em;
	text-align: left;
}

.play {
	@include base.button-reset();
	font-size: 1em;
	height: 1em;
	transition: opacity 0.2s;
	white-space: nowrap;

	&:hover {
		opacity: 0.7;
	}
}

.playIcon,
.pauseIcon {
	height: 1em;
	width: 1em;
}

.playIcon {
	display: inline-block;

	.isPlaying & {
		display: none;
	}
}

.pauseIcon {
	display: none;

	.isPlaying & {
		display: inline-block;
	}
}

.timeButtons {
	display: flex;
	justify-content: space-between;
}

.forwardControls {
	flex-direction: column-reverse;
}

.backControls {
	flex-direction: column;
}

.backControls,
.forwardControls {
	display: flex;
	@container audioPlayer (width > #{$cq-audio-controls}) {
		flex-direction: row;
	}
}

.seekBack {
	align-self: flex-start;
}

.seekForward {
	align-self: flex-end;
}

.seekBack,
.seekForward {
	@include base.button-reset();
	align-items: center;
	display: flex;
	height: var(--textsize-s);
	margin-bottom: var(--spacing-xxs);
	transition: opacity 0.2s;
	width: var(--textsize-s);
	@container audioPlayer (width > #{$cq-audio-controls}) {
		align-self: center;
		margin: 0 var(--spacing-xxs);
	}

	&:hover {
		opacity: 0.7;
	}

	svg {
		height: 100%;
		width: 100%;
	}
}
